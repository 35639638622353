import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";


const GreenEnergyCommitment = () => {
  const [/*commitmentData*/, setCommitmentData] = useState({});
  
  const [skills, setSkills] = useState("");
  const [content, setContent] = useState("");
  const [projectDetail, setProjectDetail] = useState("");
  const [q1, setq1] = useState("");
  const [q2, setq2] = useState("");
  const [q3, setq3] = useState("");

  const [wordCountError, setWordCountError] = useState("");

  const [referalData, setReferalData] = useState(null);
  const [referalId, setReferalId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [personalStatement, setPersonalStatement] = useState(false);
  const [personalStatementId, setPersonalStatementId] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [error, setError] = useState(null);
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
 console.log("token",token)
 const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer
 const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage
 const maxWords = 250; // Word limit
 const maxWordstheme = 500; // Word limit
 const maxWordsperq = 100; // Word limit

 // Function to count words
 const countWords = (text) => {
  if (typeof text !== "string") return 0; // Ensure text is a string
  return text.trim().split(/\s+/).filter(Boolean).length;
};

 
 // Generalized function to handle text changes for all fields
 const handleTextChange = (e, setter, setError) => {
   const newText = e.target.value;
   const wordCount = countWords(newText);
 
   if (wordCount > maxWords) {
     setError("You have reached the maximum word count.");
   } else {
     setError("");
     setter(newText);
   }
 };
 
 // Handle paste event
 const handlePaste = (e, text, setter, setError) => {
   const pastedData = e.clipboardData.getData('text');
   const wordCount = countWords(text + pastedData);
 
   if (wordCount > maxWords) {
     setError("Pasting this text exceeds the maximum word count.");
     e.preventDefault();
   }
 };
 const handleTextChangetheme = (e, setter, setError) => {
  const newText = e.target.value;
  const wordCount = countWords(newText);

  if (wordCount > maxWordstheme) {
    setError("You have reached the maximum word count.");
  } else {
    setError("");
    setter(newText);
  }
};

// Handle paste event
const handlePastetheme = (e, text, setter, setError) => {
  const pastedData = e.clipboardData.getData('text');
  const wordCount = countWords(text + pastedData);

  if (wordCount > maxWordstheme) {
    setError("Pasting this text exceeds the maximum word count.");
    e.preventDefault();
  }
};


const handleTextChangequestion = (e, setter, setError) => {
  const newText = e.target.value;
  const wordCount = countWords(newText);

  if (wordCount > maxWordstheme) {
    setError("You have reached the maximum word count.");
  } else {
    setError("");
    setter(newText);
  }
};

// Handle paste event
const handlePastequestion = (e, text, setter, setError) => {
  const pastedData = e.clipboardData.getData('text');
  const wordCount = countWords(text + pastedData);

  if (wordCount > maxWordstheme) {
    setError("Pasting this text exceeds the maximum word count.");
    e.preventDefault();
  }
};

 useEffect(() => {
  const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
  if (disabledStatuses.includes(status)) {
    setIsDisabled(true);
  } else {
    setIsDisabled(false);
  }
}, [status]); // Re-evaluate when the status changes


  useEffect(() => {

    // All Info

      // Fetch the latest campaign first
  fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}` // JWT token of the logged-in user
    }
  })
  .then(response => response.json())
  .then(campaignResponse => {
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign
    setLoading(false)

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // If no campaign exists, handle appropriately
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch personal statements related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(personal_statements => {
        console.log('User with personal statements:', personal_statements);

        const personalStatements = personal_statements.data; // Extract personal statements

        if (personalStatements.length > 0) {
          console.log('Personal Statement related to the user:', personalStatements[0]);

          setContent(personalStatements[0].attributes.personal_statement || "");
          setSkills(personalStatements[0].attributes.skills || "");
          setProjectDetail(personalStatements[0].attributes.project_detail || 0)
          setq1(personalStatements[0].attributes.q1 || 0)
          setq2(personalStatements[0].attributes.q2 || 0)
          setq3(personalStatements[0].attributes.q3 || 0)

          setPersonalStatement(true);
          setPersonalStatementId(personalStatements[0].id);
        } else {
          console.log('No personal statements related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user personal statements:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });

  }, []);


  // word coount
  const handleInput = (e) => {
    const newContent = e.target.value;
    const wordCount = countWords(newContent);

    if (wordCount > maxWords) {
      setError('You have reached the maximum word count.');
    } else {
      setError(null);
      setContent(newContent); // Update content only if within limit
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
   
 // Clear any previous error messages
 setErrorMessage('');

 // Validate required fields
  // Trim all inputs to remove extra spaces
  const trimmedContent = content.trim();
  const trimmedSkills = skills.trim();
  const trimmedProjectDetail = projectDetail.trim();
  const trimmedq1= q1.trim();
  const trimmedq2 = q2.trim();
  const trimmedq3 = q3.trim();

  // Validate required fields
  if (!trimmedContent || !trimmedSkills || !trimmedProjectDetail || !trimmedq1 || !trimmedq2|| !trimmedq3) {
    setErrorMessage('Please fill out all required fields with valid data.');
    return;
  }
    const postData = {
      personal_statement: content,
      skills: skills,
      project_detail:projectDetail,
      q1:q1,
      q2:q2,
      q3:q3,

    };
     console.log(postData);
     if(personalStatement){
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements/${personalStatementId}`, {
          method: 'put', 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure content-type is set to JSON
          },
          body: JSON.stringify({ data: postData }) // Send the data wrapped in a 'data' object
        });
    
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
    
        const data = await response.json();
        console.log(data);
        navigate("/application/reference-letter");
      } catch (error) {
        console.error('Error:', error);
      }
    }else{
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements`, {
        method: 'POST', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', // Ensure content-type is set to JSON
        },
        body: JSON.stringify({ data: {...postData ,applicationId},}) // Send the data wrapped in a 'data' object
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
  
      const data = await response.json();
      console.log(data);
      localStorage.setItem("personal_statement", data.id);

      navigate("/application/reference-letter");
    } catch (error) {
      console.error('Error:', error);
    }
  }
  };

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5" >

        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}

                  <div className="card ">
                    <div className="card-body">
                      <h4 >
                        Personal Statement
                      </h4>
                      <p style={{ width: "100%" }}>
                     <strong>Instructions</strong>: Use of AI tools is discouraged - we want to hear your authentic perspectives and original ideas. While AI can be a helpful tool, we value personal insights, creativity, and real-world experiences in your application.    </p>
                <p>    Through your personal statement, help us understand:</p>
                  
                      {/* <ol >
                        <li >
                        What ignites your passion in your chosen field? Share your most significant achievements and explain how your work creates a meaningful impact.
                        </li>
                        <li >
                        What draws you to this opportunity? How will this fellowship enhance your journey, and what distinct qualities would you bring to our cohort?
                        </li>
                        <li>
                        How do you plan to influence positive transformation - from your immediate workplace to broader societal impact?
                        </li>
                        <li >
                        What challenges inspire you to act? How do you hope to contribute to making the world better?
                        </li>
                        <li >
                        Where do you envision yourself in five years? How does this fellowship fit into your long-term aspirations?
                        </li>
                      </ol> */}
                      {/* <p>(Word count: 800)</p> */}
                      <div className="form-group mb-4">
                        <label htmlFor="coverLetter">What motivates you in your chosen field of work? Share your most significant achievement(s) and explain how your work has made a positive impact  <span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="coverLetter"
                          placeholder="Type Statement"
                          className="form-control w-100"
                          value={content || ""}
                          onPaste={(e) => handlePaste(e, content, setContent, setError)}
                          onChange={(e) => handleTextChange(e, setContent, setError)}
                        
                          disabled={isDisabled}

                        ></textarea>
                 <div>
                  <span>Word Count Remaining: {Math.max(maxWords - countWords(content), 0)}</span>
                  {error && <div className="text-danger">{error}</div>}
                </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="skills">Why are you interested in this opportunity? State your reasons for joining the fellowship program, what you hope to gain from it, and what distinct qualities, skills, or perspectives will you bring to our cohort.<span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="skills"
                          placeholder="List any relevant technical skills……"
                          className="form-control w-100"

                          value={skills || ''}
                          onPaste={(e) => handlePaste(e, skills, setSkills, setError)}
                          onChange={(e) => handleTextChange(e, setSkills, setError)}
                          disabled={isDisabled}

                        ></textarea>
                        <div>
                        <span>Word Count Remaining: {Math.max(maxWords - countWords(skills), 0)}</span>
                        {error && <div className="text-danger">{error}</div>}
                      </div>
                      </div>
                      <div className="form-group">
                      <label htmlFor="projectDetail">
                    <p>How do you plan to drive positive change? Describe the impact you hope to create, both within your current workspace and on a broader societal level.</p>

                  </label>
                        <textarea
                            rows="10"
                            id="projectDetail"
                            placeholder="Type..."
                            className="form-control w-100"
                            value={projectDetail}
                            onPaste={(e) => handlePaste(e, projectDetail, setProjectDetail, setError)}
                            onChange={(e) => handleTextChange(e, setProjectDetail, setError)}          
                              disabled={isDisabled}
                          ></textarea>
                     <div>
                    <span>Word Count Remaining: {Math.max(maxWords - countWords(projectDetail), 0)}</span>
                    {error && <div className="text-danger">{error}</div>}
                  </div>
                    {wordCountError && <div className="text-danger">{wordCountError}</div>}
                      </div>

                      <div className="form-group">
                      <h4 >
                      Proposed Ideas
                                </h4>
                      <label htmlFor="themeWriting">
                    <p>A core component of the fellowship is for participants to identify a critical, real-world challenge within the energy and climate sector that aligns with the thematic areas below. The challenge should reflect pressing energy transition issues, be grounded in data, and have a clear pathway to impact.</p>
                    <p>Participants will then develop a structured approach to solving this challenge - either through:</p>
                    <p>1. <strong>A Campaign or Advocacy Effort:</strong> Raising awareness, influencing policy, or mobilizing stakeholders.</p>
                    <p>2. <strong>A Research-Driven Initiative:</strong> Conducting in-depth studies to inform policies, regulations, or investment decisions.</p>
                    <p>3. <strong>A Development Sector Intervention:</strong> Designing scalable programs for the non-profit or public sector.</p>
                    <p>4. <strong>A Startup or Business Model:</strong> Creating a sustainable, market-driven solution for energy transition.</p>

                    <p><strong>Our Themes:</strong></p>
                    <button
                      type="button"
                      className="btn btn-primary text-white w-60 mb-2 d-flex justify-content-between align-items-center text-center"
                      onClick={() => toggleSection("data_digital")}
                      style={{ width: "60%" }} // Ensures width is 80%

                    >
                      <span className="ms-2"><strong>Data & Digital Innovation</strong></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className={`bi bi-chevron-${openSection === "data_digital" ? "up" : "down"}`}
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d={openSection === "data_digital"
                            ? "M1.646 4.146a.5.5 0 0 1 .708 0L8 9.793l5.646-5.647a.5.5 0 0 1 .708.708L8 11.207l-6.354-6.353a.5.5 0 0 1 0-.708z"
                            : "M1.646 11.854a.5.5 0 0 1 .708 0L8 6.207l5.646 5.647a.5.5 0 0 1 .708-.708L8 4.793l-6.354 6.353a.5.5 0 0 1-.708 0z"}
                        />
                      </svg>
                    </button>
                    {openSection === "data_digital" && (
                    <ul>
                      <li>How can digital tools improve power sector transparency and decision-making?</li>
                      <li>Can real-time data monitoring help reduce grid inefficiencies and system losses?</li>
                      <li>How can digital platforms enhance energy access in underserved areas?</li>

                    </ul>
                  )}
                        <button
                      type="button"
                      className="btn btn-primary text-white w-60 mb-2 d-flex justify-content-between align-items-center text-center"
                      onClick={() => toggleSection("green_finance")}
                      style={{ width: "60%" }} // Ensures width is 80%

                    >
                      <span className="ms-2"><strong>Green Finance & Market Development</strong></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className={`bi bi-chevron-${openSection === "green_finance" ? "up" : "down"}`}
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d={openSection === "green_finance"
                            ? "M1.646 4.146a.5.5 0 0 1 .708 0L8 9.793l5.646-5.647a.5.5 0 0 1 .708.708L8 11.207l-6.354-6.353a.5.5 0 0 1 0-.708z"
                            : "M1.646 11.854a.5.5 0 0 1 .708 0L8 6.207l5.646 5.647a.5.5 0 0 1 .708-.708L8 4.793l-6.354 6.353a.5.5 0 0 1-.708 0z"}
                        />
                      </svg>
                    </button>
                    {openSection === "green_finance" && (

                    <ul>
                      <li>What financial mechanisms can make renewable energy investments more attractive?</li>
                      <li>How can Pakistan improve its green financing framework for large-scale solar and wind projects?</li>
                      <li>What market incentives are needed to scale distributed solar or EV adoption?</li>

                    </ul>
                       )}
                    <button
                            type="button"
                            className="btn btn-primary text-white w-60 mb-2 d-flex justify-content-between align-items-center text-center"
                            onClick={() => toggleSection("energy_policy")}
                            style={{ width: "60%" }} // Ensures width is 80%

                          >
                            <span className="ms-2"><strong>Energy Policy, Planning & Governance</strong></span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className={`bi bi-chevron-${openSection === "energy_policy" ? "up" : "down"}`}
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d={openSection === "energy_policy"
                                  ? "M1.646 4.146a.5.5 0 0 1 .708 0L8 9.793l5.646-5.647a.5.5 0 0 1 .708.708L8 11.207l-6.354-6.353a.5.5 0 0 1 0-.708z"
                                  : "M1.646 11.854a.5.5 0 0 1 .708 0L8 6.207l5.646 5.647a.5.5 0 0 1 .708-.708L8 4.793l-6.354 6.353a.5.5 0 0 1-.708 0z"}
                              />
                            </svg>
                          </button>  
                          {openSection === "energy_policy" && (
     
                         <ul>
                      <li>How can regulatory frameworks support a just and inclusive energy transition?</li>
                      <li>What reforms are needed to integrate more renewables into Pakistan’s power grid?</li>
                      <li>How can policies accelerate industrial decarbonization?</li>
                    </ul>
                          )}

                  <button
                          type="button"
                          className="btn btn-primary text-white w-60 mb-2 d-flex justify-content-between align-items-center text-center"
                          onClick={() => toggleSection("decarbonization")}
                          style={{ width: "60%" }} // Ensures width is 80%

                        >
                          <span className="ms-2"><strong>Decarbonization & Technological Innovation</strong></span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className={`bi bi-chevron-${openSection === "decarbonization" ? "up" : "down"}`}
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d={openSection === "decarbonization"
                                ? "M1.646 4.146a.5.5 0 0 1 .708 0L8 9.793l5.646-5.647a.5.5 0 0 1 .708.708L8 11.207l-6.354-6.353a.5.5 0 0 1 0-.708z"
                                : "M1.646 11.854a.5.5 0 0 1 .708 0L8 6.207l5.646 5.647a.5.5 0 0 1 .708-.708L8 4.793l-6.354 6.353a.5.5 0 0 1-.708 0z"}
                            />
                          </svg>
                        </button>    
                        {openSection === "decarbonization" && (
     
                         <ul>
                      <li>What are the barriers to industrial energy efficiency and how can they be addressed?</li>
                      <li>How can carbon capture, electrification, or alternative fuels contribute to decarbonization?</li>
                      <li>What role does energy storage play in ensuring grid stability with increased renewables?</li>
                    </ul>
                       )}
                  </label>
                  
                    {wordCountError && <div className="text-danger">{wordCountError}</div>}
                      </div>


                        <div className="form-group mb-4">
                        <label htmlFor="coverLetter">What critical challenge within the energy and climate sector do you aim to address, and why is it urgent?  <span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="coverLetter"
                          placeholder="Type Statement"
                          className="form-control w-100"
                          value={q1 || ""}
                          onPaste={(e) => handlePastequestion(e, q1, setq1, setError)}
                          onChange={(e) => handleTextChangequestion(e, setq1, setError)}
                        
                          disabled={isDisabled}

                        ></textarea>
                 <div>
                  <span>Word Count Remaining: {Math.max(maxWordsperq - countWords(q1), 0)}</span>
                  {error && <div className="text-danger">{error}</div>}
                </div>
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="coverLetter">How would solving this challenge contribute to a more sustainable and resilient energy and climate landscape?  <span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="coverLetter"
                          placeholder="Type Statement"
                          className="form-control w-100"
                          value={q2 || ""}
                          onPaste={(e) => handlePastequestion(e, q2, setq2, setError)}
                          onChange={(e) => handleTextChangequestion(e, setq2, setError)}
                        
                          disabled={isDisabled}

                        ></textarea>
                 <div>
                  <span>Word Count Remaining: {Math.max(maxWordsperq - countWords(q2), 0)}</span>
                  {error && <div className="text-danger">{error}</div>}
                </div>
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="coverLetter">What strategies or approaches would you implement to create a viable and impactful solution? <span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="coverLetter"
                          placeholder="Type Statement"
                          className="form-control w-100"
                          value={q3 || ""}
                          onPaste={(e) => handlePastequestion(e, q3, setq3, setError)}
                          onChange={(e) => handleTextChangequestion(e, setq3, setError)}
                        
                          disabled={isDisabled}

                        ></textarea>
                 <div>
                  <span>Word Count Remaining: {Math.max(maxWordsperq - countWords(q3), 0)}</span>
                  {error && <div className="text-danger">{error}</div>}
                </div>
                      </div>

                     

                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button type="submit" className="btn btn-primary text-white m-1"                           disabled={isDisabled}
>
                              Save & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default GreenEnergyCommitment;
