import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { NotificationContainer, NotificationManager } from "react-notifications";
import DashboardHeader from "./DashboardHeader";
import { useNavigate } from "react-router-dom";

const AptitudeTest = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const applicationId = parseInt(localStorage.getItem("id"), 10);
  const campaignId = parseInt(localStorage.getItem("campaignId"), 10);
  const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage
  const [openSection, setOpenSection] = useState("quantitative_reasoning");

  const [answers, setAnswers] = useState({
    quantitative_reasoning: {},
    verbal_reasoning: {},
    energy_climate_trivia: {},
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [existingResponseId, setExistingResponseId] = useState(null); // Store response ID if updating



  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  

  // Fetch existing test responses
  useEffect(() => {
    const fetchExistingAnswers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL_strapi}/aptitude-test-responses?filters[application]=${applicationId}&filters[campaign]=${campaignId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch existing answers");
        }

        const data = await response.json();

        if (data?.data.length > 0) {
          const existingAnswers = data.data[0].attributes.answers;
          setAnswers(existingAnswers);
          setExistingResponseId(data.data[0].id); // Store response ID
        }
      } catch (error) {
        console.error("Error fetching existing answers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExistingAnswers();
  }, [applicationId, campaignId, token]);


  useEffect(() => {
    const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
    if (disabledStatuses.includes(status)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [status]); // Re-evaluate when the status changes


  // Handle answer selection
  const handleAnswerSelect = (section, question, answer) => {
    setAnswers((prev) => ({
      ...prev,
      [section]: { ...prev[section], [question]: answer },
    }));
    
  };

  // Submit or update test
  const submitTest = async (e) => {
    e.preventDefault();
      // Define required questions
  const requiredQuestions = {
    quantitative_reasoning: ["q1", "q2", "q3", "q4", "q5"],
    energy_climate_trivia: ["q1", "q2", "q3", "q4", "q5"],
    verbal_reasoning: ["q1", "q2", "q3", "q4", "q5"],

  };

  // Check if all required questions have answers
  for (const section in requiredQuestions) {
    for (const question of requiredQuestions[section]) {
      if (!answers[section][question]) {
        NotificationManager.error(`Please answer all questions of all sections.`);
        return; // Stop submission if any answer is missing
      }
    }
  }

    try {
      const method = existingResponseId ? "PUT" : "POST";
      const url = existingResponseId
        ? `${process.env.REACT_APP_BASE_URL_strapi}/aptitude-test-responses/${existingResponseId}`
        : `${process.env.REACT_APP_BASE_URL_strapi}/aptitude-test-responses`;

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            application: applicationId,
            campaign: campaignId,
            answers,
          },
        }),
      });
      const createdData = await response.json();
      console.log("aptitude test",createdData.data.id)
      localStorage.setItem("Aptitude_test", createdData.data.id);

      if (!response.ok) {
        throw new Error("Failed to submit test");
      }
      navigate("/application/privacy-policy-statement");

      NotificationManager.success("Test submitted successfully!");
    } catch (error) {
      NotificationManager.error(error.message);
    }
  };

  return (
    <div>
      <div className="mb-5">
        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
  <div className="col-md-10">
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-4">
        <Navbar />
      </div>
      <div className="col-sm-12 col-md-8 mb-4">
        <form onSubmit={submitTest}>
          <div className="card">
            <div className="card-body">
              <h4>Aptitude Test</h4>
              <p className="my-3">Please read the following questions carefully. The assessment consists of three sections: Quantitative Reasoning, Verbal Reasoning, and Energy & Climate Trivia.</p>
            
                      {/* Quantitative Reasoning Section */}
                      <button
                      type="button"
                      className="btn btn-primary text-white w-100 mb-2 d-flex justify-content-between align-items-center text-center"
                      onClick={() => toggleSection("quantitative_reasoning")}
                    >
                      <span className="mx-auto">Quantitative Reasoning</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className={`bi bi-chevron-${openSection === "quantitative_reasoning" ? "up" : "down"}`}
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d={openSection === "quantitative_reasoning" 
                            ? "M1.646 4.146a.5.5 0 0 1 .708 0L8 9.793l5.646-5.647a.5.5 0 0 1 .708.708L8 11.207l-6.354-6.353a.5.5 0 0 1 0-.708z"
                            : "M1.646 11.854a.5.5 0 0 1 .708 0L8 6.207l5.646 5.647a.5.5 0 0 1 .708-.708L8 4.793l-6.354 6.353a.5.5 0 0 1-.708 0z"
                          }
                        />
                      </svg>
                    </button>


                      {openSection === "quantitative_reasoning" &&             <div className="form-group mb-3">
                <h5>Quantitative Reasoning</h5>
                
                <label>1) According to the above figure, what is the number of people employed in management?</label>
                <img src="/images/01.png" alt="Question 1" className="img-fluid mb-2 d-block mx-auto" />
                {["10 million", "15 million", "21 billion", "140 million", "None of the above"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="q1" value={option} 
                       checked={answers.quantitative_reasoning?.q1 === option}
                      onChange={() => handleAnswerSelect("quantitative_reasoning", "q1", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}

                <label>2) Which one is the top view of the above pyramid?</label>
                <img src="/images/02.png" alt="Question 1" className="img-fluid mb-2 d-block mx-auto" />
                {["A", "B", "C", "D", "None of the above"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="q2" value={option} 
                                           checked={answers.quantitative_reasoning?.q2 === option}

                      onChange={() => handleAnswerSelect("quantitative_reasoning", "q2", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                  <label>3) Which is the missing piece?</label>
                  <img src="/images/03.png" alt="Question 1" className="img-fluid mb-2 d-block mx-auto" />
                {["A", "B", "C", "All of the above", "None of the above"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="q3" value={option} 
                                           checked={answers.quantitative_reasoning?.q3 === option}

                      onChange={() => handleAnswerSelect("quantitative_reasoning", "q3", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                  <label>4) Which is the missing piece?</label>
                  <img src="/images/04.png" alt="Question 1" className="img-fluid mb-2 d-block mx-auto" />
                {["A", "B", "C", "D", "None of the above"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="q4" value={option} 
                                           checked={answers.quantitative_reasoning?.q4 === option}
                      onChange={() => handleAnswerSelect("quantitative_reasoning", "q4", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                  <label>5) Which cube does the cutout shape make?</label>
                  <img src="/images/05.png" alt="Question 1" className="img-fluid mb-2 d-block mx-auto" />
                {["A", "B", "C", "D", "None of the above"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="q5" value={option} 
                                           checked={answers.quantitative_reasoning?.q5 === option}

                      onChange={() => handleAnswerSelect("quantitative_reasoning", "q5", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
              </div>}
             
              
         
   
     {/* Verbal Reasoning Section */}
            <button
              type="button"
              className="btn btn-primary text-white w-100 mb-2 d-flex justify-content-between align-items-center text-center"
              onClick={() => toggleSection("verbal_reasoning")}
            >
              <span className="mx-auto">Verbal Reasoning</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className={`bi bi-chevron-${openSection === "verbal_reasoning" ? "up" : "down"}`}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d={openSection === "verbal_reasoning" 
                    ? "M1.646 4.146a.5.5 0 0 1 .708 0L8 9.793l5.646-5.647a.5.5 0 0 1 .708.708L8 11.207l-6.354-6.353a.5.5 0 0 1 0-.708z"
                    : "M1.646 11.854a.5.5 0 0 1 .708 0L8 6.207l5.646 5.647a.5.5 0 0 1 .708-.708L8 4.793l-6.354 6.353a.5.5 0 0 1-.708 0z"
                  }
                />
              </svg>
            </button>

            {openSection === "verbal_reasoning" && (
  <div className="form-group mb-3">
    <h5>Verbal Reasoning</h5>

    {/* Question 1 */}
    <div>
      <label>1) In the early days of steam locomotion, engineers faced severe resistance from the public. Some believed
that high speeds would cause passengers to suffocate, while others feared the noise would drive livestock
mad. It took decades of demonstrations and policy shifts before rail travel gained widespread acceptance.</label>
         <div>According to the passage, how did the public initially react to steam locomotion?</div>
      {["They believed it would cause passengers to suffocate.", "They feared it would drive livestock mad.", "They welcomed it as a revolutionary technology.", "The government banned steam locomotion due to public protests.", "Cannot Say"].map((option, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="vr1"
            value={option}
            checked={answers.verbal_reasoning.q1 === option}
            onChange={() => handleAnswerSelect("verbal_reasoning", "q1", option)}
          />
          <label className="form-check-label">{option}</label>
        </div>
      ))}
    </div>

    {/* Question 2 */}
    <div>
      <label>2) The unconscious is not merely a repository of forgotten memories and repressed desires but a dynamic
system containing archetypal structures that shape human thought and behavior. These archetypes—
primordial images inherited from our collective past-manifest in dreams, myths, and cultural symbols,
influencing individuals in ways they often do not consciously recognize</label>
<div>According to the passage, in what ways do archetypes manifest?</div>
      {["As subconscious imprints that influence spoken language.", "Through dreams, myths, and cultural symbols.", " By shaping only childhood experiences and fading in adulthood.", " In the conscious mind as constructed personal memories.", "Cannot Say"].map((option, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="vr2"
            value={option}
            checked={answers.verbal_reasoning.q2 === option}
            onChange={() => handleAnswerSelect("verbal_reasoning", "q2", option)}
          />
          <label className="form-check-label">{option}</label>
        </div>
      ))}
    </div>

    {/* Question 3 */}
    <div>
      <label>3) Life's but a walking shadow, a poor player that struts and frets his hour upon the stage and then is heard
no more. It is a tale told by an idiot, full of sound and fury, signifying nothing.</label>
<div>According to the passage, which of the following is true about how life is portrayed?</div>
      {["It consists of a sequence of logical events leading to fulfillment.", "It is controlled and carefully planned like a stage performance.", "It is filled with chaotic energy yet ultimately lacks meaning.", "It is a structured narrative directed by a higher intelligence.", "Cannot Say"].map((option, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="vr3"
            value={option}
            checked={answers.verbal_reasoning.q3 === option}
            onChange={() => handleAnswerSelect("verbal_reasoning", "q3", option)}
          />
          <label className="form-check-label">{option}</label>
        </div>
      ))}
    </div>

    {/* Question 4 */}
    <div>
      <label>4) In the early 1990s, under pressure from international financial institutions, Pakistan initiated major
reforms in its power sector, shifting from a state-controlled model to privatization. The World Bank played
a central role in pushing these reforms, advocating for independent power producers (IPPs) as a means to
attract private investment and address energy shortages. While privatization led to an increase in
generation capacity, it also introduced long-term capacity payment obligations, causing financial strain on
the national exchequer. Additionally, regulatory challenges and issues of circular debt have continued to
hinder the sector's stability, raising questions about the effectiveness of the privatization model in resolving
Pakistan's energy crisis.</label>
<div>According to the passage, what was one financial consequence of privatization in Pakistan's power</div>
      {["The complete elimination of circular debt.", "A reduction in private sector involvement in power generation.", "Long-term capacity payment obligations on the national exchequer.", "The dissolution of independent power producers (IPPs).", "Cannot Say"].map((option, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="vr4"
            value={option}
            checked={answers.verbal_reasoning.q4 === option}
            onChange={() => handleAnswerSelect("verbal_reasoning", "q4", option)}
          />
          <label className="form-check-label">{option}</label>
        </div>
      ))}
    </div>

    {/* Question 5 */}
    <div>
      <label>5) Grown-ups love figures. When you tell them that you have made a new friend, they never ask you about
essential matters. They never say to you, 'What does his voice sound like? What games does he love best?
Does he collect butterflies?' Instead, they demand: 'How old is he? How many brothers does he have? How
much does he weigh? How much money does his father make?' Only then do they think they know him.</label>
      {["They believe someone's family background determines their worth.", "They focus on numerical details rather than personal qualities.", "They are more emotionally connected to new friendships.", " They avoid asking any questions about new friends.", "Cannot Say"].map((option, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="vr5"
            value={option}
            checked={answers.verbal_reasoning.q5 === option}
            onChange={() => handleAnswerSelect("verbal_reasoning", "q5", option)}
          />
          <label className="form-check-label">{option}</label>
        </div>
      ))}
    </div>
  </div>
)}


              {/* Energy & Climate Trivia Section */}
              <button
              type="button"
              className="btn btn-primary text-white w-100 mb-2 d-flex justify-content-between align-items-center text-center"
              onClick={() => toggleSection("energy_climate_trivia")}
            >
              <span className="mx-auto">Energy & Climate Trivia</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className={`bi bi-chevron-${openSection === "energy_climate_trivia" ? "up" : "down"}`}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d={openSection === "energy_climate_trivia" 
                    ? "M1.646 4.146a.5.5 0 0 1 .708 0L8 9.793l5.646-5.647a.5.5 0 0 1 .708.708L8 11.207l-6.354-6.353a.5.5 0 0 1 0-.708z"
                    : "M1.646 11.854a.5.5 0 0 1 .708 0L8 6.207l5.646 5.647a.5.5 0 0 1 .708-.708L8 4.793l-6.354 6.353a.5.5 0 0 1-.708 0z"
                  }
                />
              </svg>
            </button>
              {openSection === "energy_climate_trivia" &&     <div className="form-group mb-3">
                <h5>Energy & Climate Trivia</h5>
                
                <label>1) In the past 10 years, Pakistan’s total installed capacity for electricity generation has increased by:</label>
                {["More than 20%", "More than 40%", "More than 80%", "Doubled", "None of the above"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="energy1" value={option} 
                                           checked={answers.energy_climate_trivia?.q1 === option}

                      onChange={() => handleAnswerSelect("energy_climate_trivia", "q1", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                
                <label>2) The Carbon Border Adjustment Mechanism will enter into its definitive regime in which year?</label>
                {["2025", "2026", "2027", "2028", "Has already begun"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="energy2" value={option} 
                                           checked={answers.energy_climate_trivia?.q2 === option}

                      onChange={() => handleAnswerSelect("energy_climate_trivia", "q2", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}

                 <label>3) How many MW of wind and solar electricity did K-Electric procure in the year 2024 through auctions?</label>
                {["520 MW", "640 MW", "720 MW", "830 MW", "None of the above"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="energy3" value={option} 
                                           checked={answers.energy_climate_trivia?.q3 === option}
                      onChange={() => handleAnswerSelect("energy_climate_trivia", "q3", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}

             <label>4) Which of the following is true about the competitive market regime (CTBCM) proposed in Pakistan?</label>
                {["It will allow bilateral trade of electricity", "It will apply only for loads higher than 1 MW", "It will disincentivize renewable energy in the national grid", "Both A&B", "Both C&A"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="energy4" value={option} 
                                           checked={answers.energy_climate_trivia?.q4 === option}
                      onChange={() => handleAnswerSelect("energy_climate_trivia", "q4", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}

              <label>5) The US Environmental Protection Agency came into being as a result of:</label>
                {["Nixon’s concern for rising pollution levels", "The growing environmental impact of the cold war", "Rachel Carson’s book on the growing use of pesticides", "United Nations Framework Convention on Climate Change", "Has already begun"].map((option, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="radio" name="energy5" value={option} 
                                           checked={answers.energy_climate_trivia?.q5 === option}
                      onChange={() => handleAnswerSelect("energy_climate_trivia", "q5", option)} />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
              </div>}
              
            </div>
            <div className="card-footer">
              <div className="mc-footer">
                <div className="row text-center">
                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-primary text-white m-1" disabled={isDisabled}>Submit Test</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

      <NotificationContainer />
    </div>
  );
};

export default AptitudeTest;
